import {
	getFeatureImageFromCache,
	setFeatureImageInCache,
} from '../components/globalFeatureImages';

import { getCategoriesByIds } from '../services/BigCommerce';

const fetchFeatureImage = async (categoryId) => {
	if (!categoryId) return {};

	const cachedImage = getFeatureImageFromCache(categoryId);
	if (cachedImage) {
		return cachedImage;
	}

	try {
		const res = await getCategoriesByIds(categoryId);
		const data = res.data;

		const fetchedImage = {
			url: data[0].image_url,
			alt: data[0].name,
			catId: categoryId,
		};

		setFeatureImageInCache(categoryId, fetchedImage);

		return fetchedImage;
	} catch (error) {
		console.error('Error fetching feature image:', error);
		return {};
	}
};

export default fetchFeatureImage;
