import ReactGA4 from "react-ga4"
import ReactGA from 'react-ga'

export const initGa = () => {
	ReactGA4.initialize('G-KQBDFG532F')
	ReactGA.initialize('UA-101484271-1')
}

export const handlePageView = location => {
	if (localStorage.getItem('areCookiesAgreed') === "true") {
		ReactGA4.send({ hitType: "pageview", page: `${location.pathname} ${location.search}` })
		ReactGA.pageview(location.pathname + location.search)
	}
}