import React, { useEffect, useContext, useState, useRef } from 'react'
import { ContextLoader } from '../context'
import loadFonts from '../helpers/loadFonts'
import LinesAnim from '../components/LinesAnim'

const Loader = ({ pathname }) => {

	const [isLoading, updateIsLoading] = useContext(ContextLoader)
	const [isEnding, updateIsEnding] = useState(false)
	const [fakeCount, setFakeCount] = useState(0)
	const [isAssetsLoaded, setIsAssetsLoaded] = useState(false)
	const [isFakeCountEnded, setIsFakeCountEnded] = useState(false)

	// General loading handler
	useEffect(() => {
		let loaderTimeoutId = null

		// Launch fake counter
		setFakeCount(fakeCount + 1)

		// Combine loading promises
		handleFontsLoad().then(() => {
			setIsAssetsLoaded(true)
		})
	}, [])

	// Counter
	const fakeCountTimeoutId = useRef(null)
	useEffect(() => {
		if (fakeCount < 100) {
			fakeCountTimeoutId.current = setTimeout(() => {
				setFakeCount(fakeCount + 1)
			}, 10)
		} else {
			clearTimeout(fakeCountTimeoutId.current)
			setIsFakeCountEnded(true)
		}
	}, [fakeCount])

	// Detect assets loaded and fake count ended
	useEffect(() => {
		if (isAssetsLoaded === true && isFakeCountEnded === true) {
			updateIsEnding(true)
			setTimeout(() => {
				updateIsLoading(false)
			}, 500);
			setTimeout(() => {
				updateIsEnding(false)
			}, 2000);
		}
	}, [isAssetsLoaded, isFakeCountEnded])

	// Load fonts
	const handleFontsLoad = () => {
		return new Promise(
			(resolve) => {
				loadFonts().then(() => {
					setIsAssetsLoaded(true)
				})
			}
		)
	}

	return (
		<div className={`Loader ${isEnding ? 'Loader--Ending' : ''} ${!isLoading ? 'Loader--Ended' : ''}`}>
			<div className="Loader__Count">
				{fakeCount} / 100
			</div>
			<LinesAnim className="Loader_Lines" color="rgba(76, 75, 66, 1)"/>
		</div>
	)
}

export default Loader