import React, { useEffect, useMemo, useState } from 'react';
import {
	allFeatureIds,
	hardSurfaceTypeIds,
	madeInUs,
} from '../helpers/technologyCatIds';
import { getProductImagesById } from '../services/BigCommerce';
import SliderProductOnList from './SliderProductOnList';
import fetchFeatureImage from '../helpers/fetchFeatureImage';

const CardProductWithSlider = ({
	className,
	product,
	handleClick,
	// noMarginBottom = false,
}) => {
	const [images, setImages] = useState([]);
	const [featureImage, setFeatureImage] = useState({});
	const [thumbnailImage, setThumbnailImage] = useState(null);
	const [hardSurfaceTypeImage, setHardSurfaceTypeImage] = useState({});
	const [madeInUsTypeImage, setMadeInUsTypeImage] = useState({});

	const productUrl = product.custom_url.url;
	const categories = product.categories;

	// returns the first common id found
	const commonId = useMemo(() => {
		// for the featured images banner on product list
		// 134 and 136 are the ids for PureGrain and the only ones supposed to show on the Catalog page, for now.
		// 92, 93, 94, 95 are the ids of other than PureGrain hard surface collections, will not show featured images on the Catalog page.
		if (
			categories.includes(92) ||
			categories.includes(93) ||
			categories.includes(94) ||
			categories.includes(95)
		) {
			return null;
		}

		return categories.find((catId) => allFeatureIds.includes(catId));
	}, [categories]);

	const hardSurfaceTypeId = useMemo(
		(catId) => {
			return categories.find((catId) => hardSurfaceTypeIds.includes(catId));
		},
		[categories]
	);

	const madeInUsId = useMemo(
		(catId) => {
			return categories.find((catId) => madeInUs.includes(catId));
		},
		[categories]
	);

	const fetchProductImages = (productId) => {
		return getProductImagesById(productId)
			.then((response) => response.data)
			.catch((error) => {
				console.error('Error fetching product images:', error);
				return [];
			});
	};

	useEffect(() => {
		const fetchAndSetImages = async () => {
			try {
				const fetchedImages = await fetchProductImages(product.id);
				setImages(fetchedImages);

				const findThumbnailImage = (images) => {
					if (!images) return null;
					return images.find((image) => image.is_thumbnail === true);
				};

				setThumbnailImage(findThumbnailImage(fetchedImages));

				const fetchedFeatureImage = await fetchFeatureImage(commonId);
				const fetchedHardSurfaceTypeImage = await fetchFeatureImage(
					hardSurfaceTypeId
				);
				const fetchedMadeInUsTypeImage = await fetchFeatureImage(madeInUsId);

				setHardSurfaceTypeImage(fetchedHardSurfaceTypeImage);
				setMadeInUsTypeImage(fetchedMadeInUsTypeImage);
				setFeatureImage(fetchedFeatureImage);
			} catch (error) {
				console.error('Error fetching images:', error);
			}
		};

		fetchAndSetImages();
	}, [commonId, product.id, hardSurfaceTypeId, madeInUsId]);

	const memoizedImages = useMemo(() => images, [images]);
	const memoizedFeatureImage = useMemo(() => featureImage, [featureImage]);
	const memoizedThumbnailImage = useMemo(
		() => thumbnailImage,
		[thumbnailImage]
	);

	// console.log('memoizedFeatureImage', memoizedFeatureImage);

	return (
		<div className={`CardProduct CardProduct-Update ${className} `}>
			{memoizedImages.length > 0 && (
				<SliderProductOnList
					key={product.id}
					images={memoizedImages}
					productUrl={productUrl}
					featureImage={memoizedFeatureImage}
					hardSurfaceTypeImage={hardSurfaceTypeImage}
					madeInUsTypeImage={madeInUsTypeImage}
					porductName={product.name}
					thumbnailImage={memoizedThumbnailImage}
					handleClick={handleClick}
				/>
			)}
		</div>
	);
};

export default CardProductWithSlider;
