import React from 'react'

const LinesAnim = ({ className, color = "#EAE7DD" }) => {

	return (
		<div className={`LinesAnim ${className}`}>
			<div className="LinesAnim__Container LinesAnim__Container--Small">
				<svg className="LinesAnim__Svg" viewBox="0 0 98 1324" stroke={color} xmlns="http://www.w3.org/2000/svg"><path d="M46.583 0C80.194 88.404 97 153.27 97 194.6c0 41.33-16.806 105.326-50.417 191.991C4.624 478.94 4.624 570.742 46.583 662c41.693-90.822 41.693-182.624 0-275.409C16.194 305.844 1 241.847 1 194.6 1 147.353 16.194 82.486 46.583 0Zm0 662C80.194 750.404 97 815.27 97 856.6c0 41.33-16.806 105.326-50.417 191.991-41.959 92.349-41.959 184.151 0 275.409 41.693-90.822 41.693-182.624 0-275.409C16.194 967.844 1 903.847 1 856.6 1 809.353 16.194 744.486 46.583 662Z" fill="none" fillRule="evenodd" strokeOpacity=".15"/></svg>
			</div>
			<div className="LinesAnim__Container LinesAnim__Container--Big">
				<svg className="LinesAnim__Svg" viewBox="0 0 131 1260" stroke={color} xmlns="http://www.w3.org/2000/svg"><path d="M64.472 629.996C22.157 698.53 1 763.308 1 824.33c0 61.053 21.179 124.996 63.536 191.831 43.643 88.232 43.643 169.512 0 243.84-42.357-73.706-42.357-154.986 0-243.84C108.18 949.325 130 885.382 130 824.33c0-61.022-21.8-125.8-65.398-194.334 43.577-74.298 43.555-155.54-.066-243.728C22.18 319.433 1 255.49 1 194.438 1 133.384 22.179 68.572 64.536 0 108.18 68.573 130 133.385 130 194.437c0 61.053-21.821 124.996-65.464 191.831-42.336 88.81-42.357 170.052-.064 243.728Z" fill="none" fillRule="evenodd" strokeOpacity=".15"/></svg>
			</div>
		</div>
	)
}

export default LinesAnim