import React, { useState } from 'react'
import { graphql } from 'gatsby'
import emailjs from 'emailjs-com'
import validateEmail from '../helpers/validateEmail'

const FORM_TEMPLATE_ID = 'newsletter_template'
const EMAIL_SERVICE_ID = process.env.GATSBY_EMAILJS_SERVICE_ID
const EMAIL_USER_ID = process.env.GATSBY_EMAILJS_USER_ID

const Newsletter = ({ data }) => {

	const [userEmail, setUserEmail] = useState('')
	const [successMessage, setSuccessMessage] = useState(null)
	const [errorMessage, setErrorMessage] = useState(null)
	const [isLoading, setIsLoading] = useState(false)

	const onSubmit = () => {
		setSuccessMessage(null)
		setErrorMessage(null)

		if (validateEmail(userEmail)) {
			setIsLoading(true)
			emailjs.send(EMAIL_SERVICE_ID, FORM_TEMPLATE_ID, {user_email: userEmail}, EMAIL_USER_ID)
				.then(
					res => {
						setIsLoading(false)
						setSuccessMessage('Your email was submitted, thank you.')
					},
					err => {
						setIsLoading(false)
						setErrorMessage('There was an error while submitting your email address. Please try again later or contact us to resolve the problem.')
					}
				)
		} else {
			setErrorMessage('Please enter a valid email address.')
		}
	}

	return (
		<div className="Newsletter">
			<div className="Newsletter__Container">
				<h5 className="Newsletter__Text">{data.text.text}</h5>
				<div className="Newsletter__InputContainer">
					<input
						className="Newsletter__Input"
						type="email"
						placeholder={data.input_label.text}
						value={userEmail}
						onChange={e => setUserEmail(e.target.value)}
						onKeyPress={e => { if (e.code === 'Enter') onSubmit() }}
					/>
					<div className="Newsletter__Submit" onClick={onSubmit}>
						<svg viewBox="0 0 15 10" fill="none"><path d="M0 5h13.5m0 0L9.21 9m4.29-4L9.21 1"/></svg>
					</div>
					<div className="Newsletter__Messages">
						<div className="Newsletter__Message Newsletter__Message--Success capsSmall">{successMessage}</div>
						<div className="Newsletter__Message Newsletter__Message--Error capsSmall">{errorMessage}</div>
						{isLoading &&
							<div className="Newsletter__Message Newsletter__Message--Loading capsSmall">
								Please wait while we are processing your request...
							</div>
						}
					</div>
				</div>
			</div>
		</div>
	)
}

export default Newsletter

export const query = graphql`
  fragment Newsletter on PrismicNewsletter {
    data {
      text {
        text
      }
      input_label {
        text
      }
    }
  }
`