import React, { useState, useEffect } from 'react'
import { graphql, Link } from 'gatsby'
import Button from './Button'
import { initGa, handlePageView } from '../services/GoogleAnalytics';
import { RichText } from 'prismic-reactjs'
import linkResolver from '../utils/linkResolver'

const CookiesNotice = ({ data, location }) => {

	const [isOpen, setIsOpen] = useState(true)

	useEffect(() => {
		const showCookiesNotice = localStorage.getItem('showCookiesNotice')
		setIsOpen(showCookiesNotice ? !showCookiesNotice : true)
	}, [])

	const handleDecline = () => {
		setIsOpen(false)
		localStorage.setItem('showCookiesNotice', false)
		localStorage.setItem('areCookiesAgreed', false)
	}

	const handleAccept = () => {
		setIsOpen(false)
		localStorage.setItem('showCookiesNotice', false)
		localStorage.setItem('areCookiesAgreed', true)
		initGa()
		handlePageView(location)
	}

	return (
		<div className={`CookiesNotice ${isOpen ? 'isOpen' : ''} Popup`}>
			<div className="Popup__Title">
				<RichText render={data.cookies_title.richText}/>
			</div>
			<div className="Popup__Text">
				<RichText render={data.cookies_text.richText} linkResolver={linkResolver}/>
			</div>
			<div className="CookiesNotice__Buttons">
				<Button
					className="CookiesNotice__Button"
					label="Accept"
					onClick={handleAccept}
					colorText="#4C4B42"
					colorBackground="none"
					colorBorder="rgba(72, 71, 65, 0.15)"
				/>
				<Button
					className="CookiesNotice__Button"
					label="Decline"
					onClick={handleDecline}
					colorText="#EAE7DD"
					colorBackground="#9E9A8E"
					colorBorder="none"
				/>
			</div>
		</div>
	)
}

export default CookiesNotice

export const query = graphql`
	fragment CookiesNotice on PrismicBannerAndPopup {
		data {
			cookies_title {
				richText
			}
			cookies_text {
				richText
			}
		}
	}
`