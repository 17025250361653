import React from "react"
import { SwitchTransition, CSSTransition } from "react-transition-group"

const timeout = 1000

class Transition extends React.PureComponent {

	constructor(props) {
		super(props)
		this.sailEl = React.createRef()
		this.handleExit = this.handleExit.bind(this)
		this.handleEnter = this.handleEnter.bind(this)
	}

	handleExit() {
		this.sailEl.current.classList.add('entering')
		setTimeout(() => {
			this.sailEl.current.classList.remove('entering')
			this.sailEl.current.classList.add('entered')
			window.scrollTo(0, 0)
		}, timeout)
	}

	handleEnter() {
		this.sailEl.current.classList.remove('entered')
		this.sailEl.current.classList.add('leaving')
		setTimeout(() => {
			this.sailEl.current.classList.remove('leaving')
		}, timeout)
	}

  render() {
		const { children, location } = this.props

    return (
			<>
				<SwitchTransition mode="out-in">
					<CSSTransition
						key={location.pathname}
						timeout={timeout}
						onExit={this.handleExit}
						onEnter={this.handleEnter}
					>
						{children}
					</CSSTransition>
				</SwitchTransition>
				<div className="Transition__Sail" ref={this.sailEl}></div>
			</>
    )
  }
}
export default Transition