const CDN_BASE_URL = 'https://marvel-b1-cdn.bc0a.com/f00000000291761/df0k688akp4a6.cloudfront.net/sequences'

const loadSequence = (folder, quality, framesCount = 150) => {
	let framesLoaded = 0
	let sequenceFrames = []
	return new Promise(resolve => {
		for (let i = 0; i < framesCount; i += 1) {
			loadImage(
				`${CDN_BASE_URL}/${folder}/${quality}/${i.toString().padStart(3, '0')}.jpg`
			).then(img => {
				framesLoaded += 1
				sequenceFrames[i] = img
				if (framesLoaded === framesCount) {
					resolve(sequenceFrames)
				}
			})
		}
	})
}

const loadImage = (url) => {
	return new Promise(resolve => {
		const img = new Image()
		img.src = url
		if (img.complete === true) {
			resolve(img)
		}
		img.addEventListener('load', () => {
			resolve(img)
		})
	})
}

export {
	loadSequence,
	loadImage
}