import React from 'react';
import { graphql, Link } from 'gatsby';

const Footer = ({ data }) => {
	const linksData = [
		{
			label: 'Products',
			link: '/products',
		},
		{
			label: 'Technology',
			link: '/technology',
		},
		{
			label: 'About',
			link: '/about',
		},
		{
			label: 'Careers',
			link: '/careers',
		},
		{
			label: 'Visualizer',
			link: '/visualizer',
		},
		{
			label: 'Dealer locator',
			link: '/dealer-locator',
		},
		{
			label: 'Warranty, Care, Maintenance, & Installation',
			link: '/care-and-maintenance',
		},
		{
			label: 'Contact',
			link: '/contact',
		},
	];

	return (
		<footer className="Footer">
			<div className="Footer__ListsContainer">
				<div className="Footer__ListContainer">
					<h6 className="Footer__ListName bodySerif">Website</h6>
					<ul className="Footer__List">
						{linksData.map((el, index) => (
							<li
								className="Footer__Link bodySansSmall"
								key={`footer-link-website-${index}`}
							>
								<Link to={el.link}>{el.label}</Link>
							</li>
						))}
					</ul>
				</div>
				<div className="Footer__ListContainer">
					<h6 className="Footer__ListName bodySerif">Follow us</h6>
					<ul className="Footer__List">
						{data.social_networks_list.map((el, index) => (
							<li
								className="Footer__Link bodySansSmall"
								key={`footer-link-social-networks-${index}`}
							>
								<a href={el.link.url} target="_blank">
									{el.name.text}
								</a>
							</li>
						))}
					</ul>
				</div>
				<div className="be-ix-link-block Footer__ListContainer"></div>
			</div>
			<div className="Footer__Bottom">
				<ul className="Footer__LegalsLinks">
					<li className="Footer__LegalsLink capsSmall">
						<Link to="/terms-of-use#California%20Privacy%20Policy">
							Privacy Policy
						</Link>
					</li>
					<li className="Footer__LegalsLink capsSmall">
						<Link to="/terms-of-use">Terms of Use</Link>
					</li>
					<li className="Footer__LegalsLink capsSmall">
						<Link to="/terms-of-use#Terms%20&%20Conditions">
							Terms & Conditions
						</Link>
					</li>
					<li className="Footer__LegalsLink capsSmall">
						<a
							href="https://www.roomvo.com/services/cms/cms_documents/8fac94d144a647f59f0168d4dd98f07b/2024-05-31_S-211_Report_for_12-31-23_as_Submitted9.pdf"
							target="_blank"
						>
							<span className="Footer__AccountLinkLabel capsSmall">
								Canada S-211 Reporting
							</span>
						</a>
					</li>
				</ul>
				<div className="Footer__AccountLinks">
					<a
						href={data.dwellings_site_link.url}
						target="_blank"
						className="Footer__AccountLink"
					>
						<span className="Footer__AccountLinkLabel capsSmall">
							dwellingsef.com
						</span>
					</a>
					<a
						href={data.multifamily_site_link.url}
						target="_blank"
						className="Footer__AccountLink"
					>
						<span className="Footer__AccountLinkLabel capsSmall">
							efmultifamily.com
						</span>
					</a>
					<a
						href={data.account_page_link.url}
						target="_blank"
						className="Footer__AccountLink"
					>
						<span className="Footer__AccountLinkLabel capsSmall">
							My account
						</span>
						<svg
							className="Footer__AccountLinkIcon"
							viewBox="0 0 14 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<circle cx="6.69758" cy="4.61366" r="3.87727" stroke="#4C4B42" />
							<path
								d="M12.7258 14.3866C12.1826 13.2636 11.3365 12.3148 10.283 11.6468C9.22943 10.9789 8.01029 10.6185 6.76293 10.6063C5.51557 10.594 4.28957 10.9304 3.2231 11.5775C2.15664 12.2245 1.29209 13.1566 0.72689 14.2686"
								stroke="#4C4B42"
							/>
						</svg>
					</a>
				</div>
			</div>
		</footer>
	);
};

export default Footer;

export const query = graphql`
	fragment Footer on PrismicFooter {
		data {
			social_networks_list {
				name {
					text
				}
				link {
					url
				}
			}
			account_page_link {
				url
			}
			dwellings_site_link {
				url
			}
			multifamily_site_link {
				url
			}
		}
	}
`;
