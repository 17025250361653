import axios from 'axios';

/**
 *	Get Categories tree
 */
const getCategoriesTree = () => {
	return new Promise((resolve, reject) => {
		axios
			.get('/.netlify/functions/categories-tree')
			.then((res) => {
				resolve(res.data.data);
			})
			.catch((error) => {
				console.error(error);
				reject(error);
			});
	});
};

/**
 *	Get all Categories
 */
const getAllCategories = () => {
	return new Promise((resolve, reject) => {
		axios
			.get('/.netlify/functions/categories')
			.then((res) => {
				resolve(res.data.data);
			})
			.catch((error) => {
				console.error(error);
				reject(error);
			});
	});
};

/**
 *	Get brand by ID
 */
const getBrandById = (brandId) => {
	return new Promise((resolve, reject) => {
		axios
			.get('/.netlify/functions/brand', {
				params: {
					brandId: brandId,
				},
			})
			.then((res) => {
				resolve(res.data.data);
			})
			.catch((error) => {
				console.error(error);
				reject(error);
			});
	});
};

/**
 *	Search init: get everyproducts products names
 */
const getAllProductsForSearch = () => {
	return new Promise((resolve, reject) => {
		axios
			.get('/.netlify/functions/search-init')
			.then((res) => {
				resolve(res.data.data);
			})
			.catch((error) => {
				console.error(error);
				reject(error);
			});
	});
};

/**
 *	Get multiple products with an array of ids
 * Used in Search and Product page
 */
const getProductsById = (ids) => {
	const idsString = ids.toString();
	return new Promise((resolve, reject) => {
		axios
			.get('/.netlify/functions/products-by-ids', {
				params: {
					ids: idsString,
				},
			})
			.then((res) => {
				resolve(res.data.data);
			})
			.catch((error) => {
				console.error(error);
				reject(error);
			});
	});
};

/**
 *	Get Products by main categories. Residential or Commercial
 */

// Update: list 20 products per page, instead of 18.
// Update: default category is 'carpet', instead of residential

const GET_PRODUCTS_LIMIT = 20;

const getProductsByMainCategory = (
	page = 1,
	category = 'carpet',
	sortBy = 'newest'
) => {
	return new Promise((resolve, reject) => {
		axios
			.get('/.netlify/functions/products-by-main-category', {
				params: {
					page: page,
					limit: GET_PRODUCTS_LIMIT,
					category: category,
					sortBy: sortBy,
				},
			})
			.then((res) => {
				resolve(res.data.data);
			})
			.catch((error) => {
				console.error(error);
				reject(error);
			});
	});
};

/**
 *	Get products by categories. Used for the filters
 */
const getProductsByCategoriesIds = (
	categoriesIds,
	sortBy = 'newest',
	page = 1
) => {
	const categoriesIdsString = categoriesIds.toString();
	return new Promise((resolve, reject) => {
		axios
			.get('/.netlify/functions/products-by-categories-ids', {
				params: {
					page: page,
					limit: GET_PRODUCTS_LIMIT,
					categoriesIds: categoriesIdsString,
					sortBy: sortBy,
				},
			})
			.then((res) => {
				resolve(res.data.data);
			})
			.catch((error) => {
				console.error(error);
				reject(error);
			});
	});
};

/**
 *	Get category by id
 */
const getCategoriesByIds = (categoriesIds) => {
	return new Promise((resolve, reject) => {
		axios
			.get('/.netlify/functions/categories-by-ids', {
				params: {
					categoriesIds: categoriesIds,
				},
			})
			.then((res) => {
				resolve(res.data.data);
			})
			.catch((error) => {
				console.error(error);
				reject(error);
			});
	});
};

/**
 *	Get  product images by product Id
 */
const getProductImagesById = (id) => {
	const idsString = id.toString();
	return new Promise((resolve, reject) => {
		axios
			.get('/.netlify/functions/product-images-by-id', {
				params: {
					id: idsString,
				},
			})
			.then((res) => {
				resolve(res.data.data);
			})
			.catch((error) => {
				console.error(error);
				reject(error);
			});
	});
};

export {
	getProductsByCategoriesIds,
	getProductsByMainCategory,
	getAllProductsForSearch,
	getProductsById,
	getBrandById,
	getCategoriesTree,
	getAllCategories,
	getCategoriesByIds,
	getProductImagesById,
};
