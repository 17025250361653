import { BiChevronRight, BiMinus } from 'react-icons/bi';
import { LuSearch } from 'react-icons/lu';
import { BiPlus } from 'react-icons/bi';

import React from 'react';
const Icons = {
	user: ({ className }) => (
		<svg
			className={className}
			viewBox="0 0 14 15"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="6.69758" cy="4.61366" r="3.87727" stroke="currentColor" />
			<path
				d="M12.7258 14.3866C12.1826 13.2636 11.3365 12.3148 10.283 11.6468C9.22943 10.9789 8.01029 10.6185 6.76293 10.6063C5.51557 10.594 4.28957 10.9304 3.2231 11.5775C2.15664 12.2245 1.29209 13.1566 0.72689 14.2686"
				stroke="currentColor"
			/>
		</svg>
	),

	search: ({ className }) => (
		<svg
			className={className}
			width={10}
			height={10}
			viewBox="0 0 13 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="6.5" cy="6.5" r="5.5" stroke="currentColor" />
			<line
				x1="9.66996"
				y1="10.9607"
				x2="12.3559"
				y2="13.6466"
				stroke="currentColor"
			/>
		</svg>
	),
	searchAlt: ({ className }) => <LuSearch className={className} />,
	chevRight: ({ className }) => <BiChevronRight className={className} />,
	plusIcon: ({ className }) => <BiPlus className={className} />,
	minusIcon: ({ className }) => <BiMinus className={className} />,
};

export default Icons;
