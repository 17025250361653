class DetectColor {
  constructor() {
    this.$ = {
			sections: null,
		}
		this.detectColor = this.detectColor.bind(this)
		this.onResize = this.onResize.bind(this)
		this.menuTop = 0
		this.currentSectionColor = null
  }

  init() {
		this.currentSectionColor = null
		this.$.sections = [...document.querySelectorAll('[data-menu-color]')]
		window.addEventListener('resize', this.onResize)
		this.onResize()
		window.addEventListener('scroll', this.detectColor)
		document.addEventListener('DOMContentLoaded', () => {
			this.detectColor()
		})
		this.detectColor()
	}

	destroy() {
		if (this.currentSectionColor) {
			const prevColorsAsArray = this.currentSectionColor.split(',')
			document.body.classList.remove(...prevColorsAsArray)
			this.currentSectionColor = null
		}
		window.removeEventListener('resize', this.onResize)
		window.removeEventListener('scroll', this.detectColor)
	}

	detectColor() {
		this.$.sections.map((section, index) => {
			const sectionRect = section.getBoundingClientRect()
			if (sectionRect.top - this.menuTop <= 0 && sectionRect.bottom - this.menuTop > 0) {
				const sectionColor = section.getAttribute('data-menu-color')
				if (this.currentSectionColor !== sectionColor) {
					if (this.currentSectionColor) {
						const prevColorsAsArray = this.currentSectionColor.split(',')
						document.body.classList.remove(...prevColorsAsArray)
					}
					this.currentSectionColor = sectionColor
					const newColorsAsArray = this.currentSectionColor.split(',')
					document.body.classList.add(...newColorsAsArray)
				}
			}
		})
	}

	onResize() {
		this.menuTop = window.innerWidth * 0.05
	}

}

export default DetectColor
