import React, { useState, useRef, useEffect } from 'react';
import PreloadImage from './PreloadImage';
import prefixImageLinkWithWebp from '../utils/prefixImageLinkWithWebp';
import { Link } from 'gatsby';
import Button from '../components/Button';

const SliderProductOnList = ({
	images,
	productUrl,
	featureImage,
	hardSurfaceTypeImage,
	madeInUsTypeImage,
	porductName,
	thumbnailImage,
	handleClick,
}) => {
	const selectorEl = useRef(null);
	const headerEl = useRef(null);
	const listEl = useRef(null);
	const [isExpanded, setIsExpanded] = useState(false);
	const [productLink, setProductLink] = useState(productUrl);
	const [zoomedImage, setZoomedImage] = useState(thumbnailImage);
	const [featuredImage, setFeaturedImage] = useState(featureImage);
	const [typeImage, setHardSurfaceTypeImage] = useState(hardSurfaceTypeImage);
	const [madeInUsImage, setMadeInUsTypeImage] = useState(madeInUsTypeImage);

	const [bgClass, setBgClass] = useState('');

	const [productCategory, setProductCategory] = useState('');

	const [currentIndex, setCurrentIndex] = useState(0);

	const [sortedImages, setSortedImages] = useState([]);

	/**
	 * Expand
	 */

	useEffect(() => {
		if (!isExpanded && selectorEl.current) {
			selectorEl.current.addEventListener('transitionend', () => {
				listEl.current.classList.add('Hidden');
			});
		}
		if (isExpanded && selectorEl.current) {
			selectorEl.current.addEventListener('transitionend', () => {
				listEl.current.classList.remove('Hidden');
			});
		}

		return () => {
			if (!selectorEl.current) return;
			selectorEl.current.removeEventListener('transitionend', () => {});
		};
	}, [isExpanded, selectorEl.current]);

	const handleClickOnHeader = () => {
		if (!isExpanded) {
			listEl.current.classList.remove('Hidden');
		}
		setIsExpanded(!isExpanded);
	};

	/**
	 *	Change images
	 */
	const handleSelectorClick = (e, image) => {
		e.preventDefault();
		const sluggedColor = image.description.replace(' ', '_');
		const newUrl = productUrl + `?color=${sluggedColor}`;
		const index = parseInt(
			e.target
				.closest('[data-product-slider-index]')
				.getAttribute('data-product-slider-index')
		);
		setProductLink(newUrl);
		setZoomedImage(image);
		setCurrentIndex(index);
		setIsExpanded(false);
	};

	useEffect(() => {
		setZoomedImage(thumbnailImage);
		setFeaturedImage(featureImage);
		setHardSurfaceTypeImage(hardSurfaceTypeImage);
		setMadeInUsTypeImage(madeInUsTypeImage);
	}, [thumbnailImage, featureImage, hardSurfaceTypeImage, madeInUsTypeImage]);

	useEffect(() => {
		if (!featureImage.url) return;
		const getBgClass = (catId) => {
			switch (catId) {
				// carpet
				case 83:
					return 'twistX';
				case 81:
					return 'highDef';
				case 82:
					return 'pureColor';
				case 127:
					return 'pureColorHighDef';
				// hard surface
				case 134:
					return 'pureGrainHighDef';
				case 136:
					return 'pureColor';
				default:
					return 'pureColor';
			}
		};
		const getProductCategory = (catId) => {
			switch (catId) {
				// carpet
				case 83:
					return 'TwistX';
				case 81:
					return 'High Def';
				case 82:
					return 'PureColor';
				case 127:
					return 'PureColor High Def';
				// hard surface
				case 134:
					return 'PureGrain High Def';
				case 136:
					return 'PureColor';
				default:
					return 'PureColor';
			}
		};
		const className = getBgClass(featureImage.catId);
		const productType = getProductCategory(featureImage.catId);

		setBgClass(className);
		setProductCategory(productType);
	}, [featureImage]);

	useEffect(() => {
		const sImages = [...images].sort((a, b) => a.sort_order - b.sort_order);

		const filteredImages = sImages.filter((image) => {
			const endsWithExcludedSuffix = / (V|TB|RS)$/;
			return (
				image.description === zoomedImage.description ||
				!endsWithExcludedSuffix.test(image.description)
			);
		});
		const updatedImages = filteredImages.map((image, index) => ({
			...image,
			sort_order: index,
		}));

		updatedImages.sort((a, b) => a.sort_order - b.sort_order);

		setSortedImages(updatedImages);
	}, [images]);

	return (
		<div className="SliderProduct SliderProduct__OnList">
			<Link to={productLink} onClick={handleClick ? handleClick : null}>
				<div className="SliderProduct__ImagesContainer">
					{featuredImage.url && (
						<div className={`SliderProduct__FeatureImage ${bgClass}`}>
							<img
								className=""
								src={featuredImage.url}
								alt={featuredImage.alt}
							/>
						</div>
					)}
					{typeImage.url && (
						<div className={`SliderProduct__HardSurfaceTypeImage`}>
							<img className="" src={typeImage.url} alt={typeImage.alt} />
						</div>
					)}
					{madeInUsImage.url && (
						<div className={`SliderProduct__MadeInUsTypeImage`}>
							<img className="" src={madeInUsImage.url} alt="Made in the USA" />
						</div>
					)}
					{zoomedImage && (
						<div className={`SliderProduct__ImageContainer current`}>
							<PreloadImage
								className="SliderProduct__Image"
								src={prefixImageLinkWithWebp(zoomedImage.url_zoom)}
								alt={`${productCategory} ${porductName} ${zoomedImage.description}`}
							/>
						</div>
					)}
				</div>

				<div
					className={`SliderProduct__Selector SliderProduct__Selector-Update ${
						isExpanded ? 'isExpanded' : ''
					}`}
					ref={selectorEl}
				>
					<div
						className="SliderProduct__SelectorHeader"
						ref={headerEl}
						role="button"
						tabIndex="0"
					>
						<div className="SliderProduct__ProductInfoRow">
							<p className="SliderProduct__SelectorHeaderName SliderProduct__SelectorHeaderName-Update pdName bodySansSmall bodySansMobile caps">
								{porductName}
							</p>
							<p className="SliderProduct__SelectorHeaderName SliderProduct__SelectorHeaderName-Update colorName bodySansSmall bodySansMobile">
								{sortedImages.length > 0 &&
									sortedImages[currentIndex].description}
							</p>
						</div>
						<div>
							<div className="SliderProduct__ProductColorRow">
								{zoomedImage && (
									<img
										className="SliderProduct__SelectorHeaderImage selected"
										src={prefixImageLinkWithWebp(zoomedImage.url_tiny)}
										alt={`Color Name/ID: ${zoomedImage.description}`}
									/>
								)}
								{sortedImages
									.filter((image, index) => index !== zoomedImage.sort_order)
									.slice(0, 3)
									.map((image, index) => (
										<img
											key={`slider-product-color-${index}`}
											className="SliderProduct__SelectorHeaderImage SliderProduct__SelectorHeaderImageOptions"
											src={prefixImageLinkWithWebp(image.url_tiny)}
											alt={`Color Name/ID: ${image.description}`}
											onClick={(e) => handleSelectorClick(e, image)}
											data-product-slider-index={index}
											role="button"
											tabIndex="0"
										/>
									))}
								<div className="SliderProduct__ProductInfoRow color-row">
									<p className="SliderProduct__SelectorHeaderName SliderProduct__SelectorHeaderName-Update pdName bodySansSmall bodySansMobile caps">
										{porductName}
									</p>
									<p className="SliderProduct__SelectorHeaderName SliderProduct__SelectorHeaderName-Update colorName bodySansSmall bodySansMobile">
										{sortedImages.length > 0 &&
											sortedImages[currentIndex].description}
									</p>
								</div>

								<div className="SliderProduct__SelectorHeaderCount bodySansSmall bodySansMobile">
									{isExpanded
										? '-'
										: sortedImages.length > 4 && `+${sortedImages.length - 4}`}
								</div>
							</div>
							<Button
								className="SliderProduct__ViewDetails"
								label={'Product Details'}
								link={productLink}
								colorText="#4C4B42"
								colorBackground="#E1DDD4"
								colorBorder="none"
							/>
						</div>
					</div>

					<ul
						className={`SliderProduct__SelectorList SliderProduct__SelectorList-Update Hidden`}
						ref={listEl}
					>
						{sortedImages.map((image, index) => (
							<div
								className="SliderProduct__SelectorItem SliderProduct__SelectorItem-Update"
								key={`slider-product-selector-${index}`}
								onClick={(e) => handleSelectorClick(e, image)}
								data-product-slider-index={index}
								role="button"
								tabIndex="0"
							>
								<div
									className="SliderProduct__SelectorItemImage"
									style={{ backgroundImage: `url(${image.url_tiny})` }}
								></div>
								<div className="SliderProduct__SelectorItemName SliderProduct__SelectorItemName-Update bodySansSmall bodySansMobile">
									{image.description}
								</div>
							</div>
						))}
					</ul>
				</div>
			</Link>
		</div>
	);
};

export default SliderProductOnList;
