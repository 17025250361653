import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'

const BannerAndPopup = ({ data }) => {

	/**
	*	Banner banner
	*/
	const [isBannerOpen, setIsBannerOpen] = useState(false)
	useEffect(() => {
		setTimeout(() => {
			if (data.is_banner_activated) {
				setIsBannerOpen(true)
			}
		}, 7500);
	}, [])

	useEffect(() => {
		document.body.classList.toggle('isBannerOpen', isBannerOpen)
	}, [isBannerOpen])

	/**
	*	Popup banner
	*/
	const [isPopupOpen, setIsPopupOpen] = useState(false)
	useEffect(() => {
		setTimeout(() => {
			if (data.is_popup_activated) {
				setIsPopupOpen(true)
			}
		}, 7500);
	}, [])

	return (
		<div className="BannerAndPopup">
			<div className={`Banner ${isBannerOpen === true ? 'isOpen' : ''}`}>
				<div className="Banner__Container">
					<div className="Banner__Text bodySansSmall">
						<RichText render={data.banner_text.richText}/>
					</div>
					<div className="Banner__Close" onClick={() => setIsBannerOpen(false)}></div>
				</div>
			</div>
			<div className={`Popup ${isPopupOpen ? 'isOpen' : ''}`}>
				<div className="Popup__Title">
					<RichText render={data.popup_title.richText}/>
				</div>
				<div className="Popup__Text">
					<RichText render={data.popup_text.richText}/>
				</div>
				<div className="Popup__Close" onClick={() => setIsPopupOpen(false)}></div>
			</div>
		</div>
	)
}

export default BannerAndPopup

export const query = graphql`
	fragment BannerAndPopup on PrismicBannerAndPopup {
		data {
			is_banner_activated
			banner_text {
				richText
			}
			is_popup_activated
			popup_title {
				richText
			}
			popup_text {
				richText
			}
		}
	}
`