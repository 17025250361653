import React from 'react'
import { Link } from 'gatsby'

const Button = ({
	className,
	label,
	link = '/',
	target = null,
	isFilled = false,
	onClick = null,
	colorText = '#4C4B42',
	colorBackground = 'none',
	colorBorder = '#484741'
}) => {

	const buttonStyles = {
		color: colorText,
		backgroundColor: colorBackground,
		border: `solid thin ${colorBorder}`
	}

	if (!onClick) {
		return (
			<Link
				className={`Button caps capsMobile ${className} ${isFilled ? 'isFilled' : ''}`}
				to={link}
				target={target}
				style={buttonStyles}
			>
				<div className="Button__Container">
					<span>{label}</span>
					<span>{label}</span>
				</div>
			</Link>
		)
	}
	else {
		return (
			<div
				className={`Button caps capsMobile ${className} ${isFilled ? 'isFilled' : ''}`}
				onClick={onClick}
				style={buttonStyles}
			>
				<div className="Button__Container">
					<span>{label}</span>
					<span>{label}</span>
				</div>
			</div>
		)
	}
}

export default Button