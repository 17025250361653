const prefixImageLinkWithWebp = link => {
  if (!link) return
  if (link.includes('https://marvel-b1-cdn.bc0a.com/f00000000291761')) {
    return link
  }
  let cleanedLink = null
  if (link.includes('https://')) {
    cleanedLink = link.replaceAll('https://', 'https://marvel-b1-cdn.bc0a.com/f00000000291761/')
  } 
  else if (link.includes('http://')) {
    cleanedLink = link.replaceAll('http://', 'https://marvel-b1-cdn.bc0a.com/f00000000291761/')
  }
  return cleanedLink
}

export default prefixImageLinkWithWebp