import React, { useState, useEffect } from 'react';
import prefixImageLinkWithWebp from '../utils/prefixImageLinkWithWebp';

const PreloadImage = ({ src, alt, className, style }) => {
	const [imageSrc, setImageSrc] = useState(null);
	useEffect(() => {
		const img = new Image();
		const prefixedSrc = prefixImageLinkWithWebp(src);
		img.onload = () => {
			setImageSrc(prefixedSrc);
		};
		img.src = prefixedSrc;
	}, [src]);

	const [isImageLoaded, setIsImageLoaded] = useState(false);
	useEffect(() => {
		if (imageSrc !== null) {
			setTimeout(() => {
				setIsImageLoaded(true);
			}, 500);
		}
	}, [imageSrc]);

	return (
		<div
			className={`PreloadImage ${className} ${
				isImageLoaded ? 'PreloadImage--Loaded' : ''
			}`}
			style={style}
		>
			<img className="PreloadImage__Image" alt={alt} src={imageSrc} />
		</div>
	);
};

export default PreloadImage;
