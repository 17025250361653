const linkResolver = (doc) => {
  if (doc.type === 'homepage') {
    return `/`
  }
	else if (doc.type === 'dealer_locator') {
    return `/dealer-locator`
  }
	else if (doc.type === 'care_and_maintenance') {
    return `/care-and-maintenance`
  }
	else if (doc.type === 'terms_of_use') {
    return `/terms-of-use`
  }
  return `/${doc.type}`
}

module.exports = linkResolver