import React, { useEffect, useState, useMemo, useRef } from 'react';
import debounce from 'lodash.debounce';
import {
	getAllProductsForSearch,
	getProductsById,
	getAllCategories,
} from '../services/BigCommerce.js';
import CardProductWithSlider from './CardProductWithSlider.js';

const Search = ({ isOpen = false, onClose }) => {
	const [allProducts, setAllProducts] = useState([]);
	const [allCategories, setAllCategories] = useState([]);
	const [results, setResults] = useState([]);
	const [isNoResults, setIsNoResults] = useState(false);
	const inputEl = useRef(null);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		document.body.classList.toggle('noScroll', isOpen);
		if (isOpen === true) {
			inputEl.current.focus();
		}
	}, [isOpen]);

	useEffect(() => {
		if (
			isOpen === true &&
			allProducts.length === 0 &&
			allCategories.length === 0
		) {
			getAllProductsForSearch()
				.then((res) => {
					setAllProducts(res);
				})
				.catch((err) => {
					console.log(err);
				});
			getAllCategories()
				.then((res) => {
					setAllCategories(res.data);
				})
				.catch((err) => {
					console.log(err);
				});
		}
	}, [isOpen, allProducts, allCategories]);

	const handleSearch = (e) => {
		if (allProducts.length === 0) return;

		setResults([]);
		setIsNoResults(false);
		const searchValue = e.target.value;
		if (searchValue.length === 0) return;

		setIsLoading(true);

		const lowerCaseSearchValue = searchValue.toLowerCase();
		const filteredCategories = allCategories.filter((category) => {
			const lowerCaseName = category.name.toLowerCase();
			if (lowerCaseName.search(lowerCaseSearchValue) > -1) {
				return category;
			}
		});
		// If one image description has the search value in it, return true
		const filterImagesDescriptions = (product) => {
			return product.images.filter((image) => {
				const lowerCaseImageDescription = image.description.toLowerCase();
				if (lowerCaseImageDescription.search(lowerCaseSearchValue) > -1) {
					return image;
				}
			});
		};
		let filteredProducts = allProducts.filter((product) => {
			const lowerCaseName = product.name.toLowerCase();
			// search in products names
			if (lowerCaseName.search(lowerCaseSearchValue) > -1) {
				return product;
			}
			// search in products keywords
			else if (product.search_keywords.search(lowerCaseSearchValue) > -1) {
				return product;
			}
			// search in categories
			else if (filteredCategories.length > 0) {
				let productHasCategory = false;
				const filteredProductsByCategories = filteredCategories.map(
					(category) => {
						if (product.categories.includes(category.id) === true) {
							productHasCategory = true;
						}
					}
				);
				if (productHasCategory) {
					return product;
				}
			}
			// search in images descriptions (correspond to the colors names and numbers)
			else if (filterImagesDescriptions(product).length > 0) {
				return product;
			}
		});
		if (filteredProducts.length > 108) {
			filteredProducts = filteredProducts.slice(0, 108);
		}
		if (filteredProducts.length > 0) {
			const productsIds = filteredProducts.map((p) => p.id);
			getProductsById(productsIds).then((res) => {
				setResults(res.data);
				setIsLoading(false);
			});
		} else {
			setIsLoading(false);

			setIsNoResults(true);
		}
	};

	const debouncedChangeHandler = useMemo(
		() => debounce(handleSearch, 800),
		[allProducts]
	);

	const closeSearch = (e) => {
		if (e.target.closest('.Search__Container') !== null) {
			return;
		}
		setTimeout(() => {
			inputEl.current.value = '';
		}, 1000);
		setResults([]);
		setIsNoResults(false);
		onClose();
	};

	return (
		<div className={`Search ${isOpen ? 'isOpen' : ''}`}>
			<div className="Search__Container">
				<input
					className="Search__Input h4"
					type="search"
					// placeholder="Search"
					placeholder={allProducts.length === 0 ? 'Preparing...' : 'Search'}
					onChange={debouncedChangeHandler}
					ref={inputEl}
					disabled={allProducts.length === 0 ? true : false}
				/>
			</div>
			<div
				className={`Search__ResultsContainer Search__ResultsContainer-Update ${
					results.length > 0 || isNoResults === true || isLoading === true
						? 'isVisible'
						: ''
				}`}
			>
				{isNoResults && (
					<div className="Search__NoResultsMessage bodySans">
						There are no products corresponding to your search.
					</div>
				)}
				{isLoading && (
					<div className="Search__NoResultsMessage bodySans">
						Searching...Please wait a moment.
					</div>
				)}
				{results.length > 0 &&
					results.map((product, index) => {
						let layoutIndex = index % 18;
						return (
							<CardProductWithSlider
								product={product}
								key={`search-results-cardproduct-${index}`}
								className="Search__CardProduct"
								handleClick={closeSearch}
							/>
						);
					})}
			</div>
			<div className="Search__Close" onClick={closeSearch}></div>
		</div>
	);
};

export default Search;
