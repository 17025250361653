// the return is an object :
// { isPortrait: [Mobile || Tablet] && [ Portrait || shortSidePortrait],
//   isLandscope: [Mobile || Tablet] && [ Landscope || LongSideLandscope],
//   isDesktop: ![Mobile || Tablet],
// }
function detectMobileOrTabletByUserAgent() {
	const deviceLayout = {
		isPortrait: false,
		isLandscape: false,
		isDesktop: true,
	};

	if (typeof window !== 'undefined') {
		const userAgent = window.navigator.userAgent.toLowerCase();
		const touchScreen = window.navigator.maxTouchPoints > 1;
		const isIpad = userAgent.includes('macintosh') && touchScreen;

		const isWindows = userAgent.includes('windows');

		const isSurface = isWindows && touchScreen;

		const isLandscape =
			window.screen.orientation &&
			typeof window.screen.orientation.type === 'string' &&
			window.screen.orientation.type.includes('landscape');

		const isLongSide = window.innerWidth > window.innerHeight ? true : false;

		const isLongSideLandscape = isLandscape || isLongSide;

		const isMobileOrTablet =
			userAgent.includes('ipad') ||
			isIpad ||
			userAgent.includes('iphone') ||
			userAgent.includes('android') ||
			isSurface
				? true
				: false;

		if (isMobileOrTablet && !isLongSideLandscape) {
			deviceLayout.isPortrait = true;
			deviceLayout.isLandscape = false;
			deviceLayout.isDesktop = false;
		}

		if (isMobileOrTablet && isLongSideLandscape) {
			deviceLayout.isPortrait = false;
			deviceLayout.isLandscape = true;
			deviceLayout.isDesktop = false;
		}

		return deviceLayout;
	}
	return deviceLayout;
}

export default detectMobileOrTabletByUserAgent;
