import React, { useEffect, useRef, useState } from 'react';
import { ContextLoader, ContextSequences } from '../context';
import DetectColor from '../helpers/detectColor';
import { loadSequence } from '../helpers/loadSequence';
import '../helpers/noSSR';
import { handlePageView, initGa } from '../services/GoogleAnalytics';
import '../styles/style.scss';
import BannerAndPopup from './BannerAndPopup';
import CookiesNotice from './CookiesNotice';
import Footer from './Footer';
import Header from './Header';
import Loader from './Loader';
import Newsletter from './Newsletter';
import Transition from './Transition';
import Nav from './Nav';
import detectMobileOrTabletByUserAgent from '../helpers/detectIsMobileOrTablet';

const Layout = ({ children, location, data }) => {
	const [isProductPage, setIsProductPage] = useState(false);
	const templateType = children.type.name;
	const hasPrismicProductField =
		data.prismicProduct || data.prismicProducts || data.prismicHomepage
			? true
			: false;
	const hasTemplateType =
		templateType === 'Product' ||
		templateType === 'Products' ||
		templateType === 'Homepage'
			? true
			: false;

	useEffect(() => {
		if (hasPrismicProductField || hasTemplateType) {
			setIsProductPage(true);
		} else {
			setIsProductPage(false);
		}
	}, [hasTemplateType, hasPrismicProductField]);

	const [isLoading, updateIsLoading] = useState(true);

	const [deviceLayout, setDeviceLayout] = useState({});

	const detectColor = useRef(new DetectColor());
	const detectColorTimeoutId = useRef(null);
	useEffect(() => {
		detectColorTimeoutId.current = setTimeout(() => {
			clearTimeout(detectColorTimeoutId.current);
			detectColor.current.destroy();
			detectColor.current.init();
		}, 2000);
	}, [location.pathname]);

	useEffect(() => {
		if (!data.prismicFooter) window.location.reload();
	}, [location, data.prismicFooter]);

	/*
	 * Preload sequences images
	 */
	const sequencesDefault = { sd01: [], sd02: [], hd01: [], hd02: [] };
	const [sequences, setSequences] = useState(sequencesDefault);
	useEffect(() => {
		// Desktop only
		if (deviceLayout.isDesktop || deviceLayout.isLandscape) {
			// Load seq1 in sd
			loadSequence('01', '1200').then((sequence) => {
				setSequences((prevState) => ({ ...prevState, sd01: sequence }));
				// Load seq2 in sd
				loadSequence('02', '1200').then((sequence) => {
					setSequences((prevState) => ({ ...prevState, sd02: sequence }));
					// Load seq1 in hd
					loadSequence('01', '2400').then((sequence) => {
						setSequences((prevState) => ({ ...prevState, hd01: sequence }));
						// Load seq2 in hd
						loadSequence('02', '2400').then((sequence) => {
							setSequences((prevState) => ({ ...prevState, hd02: sequence }));
						});
					});
				});
			});
		} else {
			loadSequence('01', '400').then((sequence) => {
				setSequences((prevState) => ({ ...prevState, sd01: sequence }));
				loadSequence('02', '400', 111).then((sequence) => {
					setSequences((prevState) => ({ ...prevState, sd02: sequence }));
					loadSequence('01', '900').then((sequence) => {
						setSequences((prevState) => ({ ...prevState, hd01: sequence }));
						loadSequence('02', '900', 111).then((sequence) => {
							setSequences((prevState) => ({ ...prevState, hd02: sequence }));
						});
					});
				});
			});
		}
	}, [isLoading]);

	// const detectAndHandleOrientation = debounce(() => {
	// 	const result = detectMobileOrTabletByUserAgent();
	// 	setDeviceLayout(result);
	// }, 200);

	const detectAndHandleOrientation = () => {
		const result = detectMobileOrTabletByUserAgent();
		setDeviceLayout(result);
	};

	useEffect(() => {
		const initialResult = detectMobileOrTabletByUserAgent();
		setDeviceLayout(initialResult);

		if (typeof window === 'undefined') return;

		if (window.screen.orientation) {
			window.screen.orientation.addEventListener(
				'change',
				detectAndHandleOrientation
			);
		}
		if (window.onorientationchange) {
			window.addEventListener('orientationchange', detectAndHandleOrientation);
		}

		window.addEventListener('resize', detectAndHandleOrientation);

		return () => {
			if (typeof window === 'undefined') return;
			if (window.screen.orientation) {
				window.screen.orientation.removeEventListener(
					'change',
					detectAndHandleOrientation
				);
			}
			if (window.onorientationchange) {
				window.removeEventListener(
					'orientationchange',
					detectAndHandleOrientation
				);
			}
			window.removeEventListener('resize', detectAndHandleOrientation);
		};
	}, []);

	// Detect if page is scrolled
	const [isPageScrolled, setIsPageScrolled] = useState(false);
	useEffect(() => {
		document.body.classList.toggle('pageScrolled', isPageScrolled);
		let rafId = null;
		const checkScroll = () => {
			if (window.scrollY > 50 && isPageScrolled === false) {
				setIsPageScrolled(true);
			} else if (window.scrollY <= 50 && isPageScrolled === true) {
				setIsPageScrolled(false);
			}
			rafId = requestAnimationFrame(checkScroll);
		};
		rafId = requestAnimationFrame(checkScroll);
		return () => {
			cancelAnimationFrame(rafId);
		};
	}, [isPageScrolled]);

	/*
	 *	Google Analytics
	 */
	useEffect(() => {
		if (localStorage.getItem('areCookiesAgreed') === 'true') {
			initGa();
		}
	}, []);

	useEffect(() => {
		handlePageView(location);
	}, [location]);

	return (
		<div>
			{/* <!-- Google Tag Manager (noscript) --> */}
			<noscript>
				{`<iframe
					src="https://www.googletagmanager.com/ns.html?id=GTM-PXGGRQP"
					height="0"
					width="0"
					style="display:none;visibility:hidden"
				></iframe>`}
			</noscript>
			{/* <!-- End Google Tag Manager (noscript) --> */}
			<ContextLoader.Provider value={[isLoading, updateIsLoading]}>
				{data.prismicFooter && (
					<ContextSequences.Provider value={[sequences, setSequences]}>
						{deviceLayout.isPortrait ? (
							<Nav
								accountPageLink={data.prismicFooter.data.account_page_link}
								dwellingsSiteLink={data.prismicFooter.data.dwellings_site_link}
								multifamilySiteLink={
									data.prismicFooter.data.multifamily_site_link
								}
								isProductPage={isProductPage}
								isTablet={deviceLayout.isPortrait}
								isDeskTop={deviceLayout.isDesktop || deviceLayout.isLandscape}
							/>
						) : (
							<Header
								accountPageLink={data.prismicFooter.data.account_page_link}
								dwellingsSiteLink={data.prismicFooter.data.dwellings_site_link}
								multifamilySiteLink={
									data.prismicFooter.data.multifamily_site_link
								}
								isDesktop={deviceLayout.isDesktop || deviceLayout.isLandscape}
							/>
						)}

						<Loader pathname={location.pathname} />
						<Transition location={location}>
							<div className="App__TransitionContainer">{children}</div>
						</Transition>
						<BannerAndPopup data={data.prismicBannerAndPopup.data} />
						<CookiesNotice
							data={data.prismicBannerAndPopup.data}
							location={location}
						/>
						<Newsletter data={data.prismicNewsletter.data} />
						<Footer data={data.prismicFooter.data} />
					</ContextSequences.Provider>
				)}
			</ContextLoader.Provider>
		</div>
	);
};

export default Layout;
